import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel,Container} from "react-bootstrap";
import ana from "../img/aboutus/ana.jpg";

export default function Nosotros() {
  const teamMembers = [
    {
      name: "Ana García",
      role: "CEO",
      image: ana,
    },
    {
      name: "Carlos Rodríguez",
      role: "CTO",
      image: "/placeholder.svg?height=100&width=100",
    },
    {
      name: "Laura Martínez",
      role: "Diseñadora Principal",
      image: "/placeholder.svg?height=100&width=100",
    },
    {
      name: "Miguel Sánchez",
      role: "Director de Marketing",
      image: "/placeholder.svg?height=100&width=100",
    },
  ];

  const timeline = [
    { year: 2010, event: "Fundación de la empresa" },
    { year: 2015, event: "Expansión a mercados internacionales" },
    { year: 2018, event: "Lanzamiento del producto estrella" },
    { year: 2023, event: "Alcanzamos el millón de clientes" },
  ];


  return (
    <div>
      <Container>
        <header className="container-1">
          <h1 className="display-4 mb-3">Sobre Nuestra Empresa</h1>
          <p className="lead text-muted">Innovando para un mañana mejor</p>
        </header>

        <section className="mb-5">
          <h2 className="h2 mb-3">Nuestra Misión</h2>
          <p className="lead">
            En Nuestra Empresa, nos esforzamos por crear soluciones innovadoras
            que mejoren la vida de las personas y amplíen los límites de la
            tecnología. Nuestro compromiso con la excelencia y las prácticas
            sostenibles impulsa todo lo que hacemos.
          </p>
        </section>

        <section className="mb-5">
          <h2 className="h2 mb-4">Nuestro Equipo</h2>
          <div className="row">
            {teamMembers.map((member, index) => (
              <div key={index} className="col-md-6 col-lg-3 mb-4">
                <div className="card h-100">
                  <img
                    src={member.image}
                    className="card-img-top"
                    alt={member.name}
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">{member.name}</h5>
                    <p className="card-text text-muted">{member.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-5">
          <h2 className="h2 mb-4">Nuestra Historia</h2>
          <Carousel>
            {timeline.map((item, index) => (
              <Carousel.Item key={index}>
                <div className="bg-light text-center p-5">
                  <h3>{item.year}</h3>
                  <p>{item.event}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </section>

        <section className="bg-light p-5 rounded">
          <div className="row align-items-center">
            <div className="col-md-8 mb-3 mb-md-0">
              <h2 className="h3 mb-2">Únete a Nuestro Equipo</h2>
              <p className="mb-0">
                Siempre estamos buscando personas talentosas para unirse a
                nuestra misión.
              </p>
            </div>
            <div className="col-md-4 text-md-end">
              <button className="btn btn-primary">
                Ver Posiciones Abiertas
              </button>
            </div>
          </div>
        </section>
        <footer className="mt-5 text-center">
          <p className="text-muted">
            <i className="bi bi-geo-alt-fill me-2"></i>
            Calle Innovación 123, Ciudad Tecnológica, 12345
          </p>
        </footer>
      </Container>
    </div> 
  );
}
