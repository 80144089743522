//import { useState } from 'react';
//import Axios from "axios";
import "./App.css";
//import PreLoader1 from './components/PreLoader';
import AppRouter from "./router/AppRouter";
import { NavLink } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { LogIn } from "lucide-react";
import Sesion from "../src/pages/Login";

function App() {
  console.log(process.env.REACT_APP_API_URL);
  return (
    <BrowserRouter>
      <div className="sesion">
        <button variant="outline-primary" className="boton" onClick={Sesion}>
          <LogIn className="me-2" color="#61dafb" size={8} />
          Iniciar Sesion
        </button>
      </div>
      <div className="topnav">
        <NavLink to="/home">Inicio</NavLink>
        <NavLink to="/tabla">Servicios</NavLink>
        <NavLink to="/formulario">Registro</NavLink>
        <NavLink to="/nosotros">Nosotros</NavLink>
        <NavLink to="/login" hidden="true">
          Login
        </NavLink>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <AppRouter />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
/*
const [nombre,setNombre] = useState("");
const [edad,setEdad] = useState(0);
const [pais,setPais] = useState("");
const [cargo,setCargo] = useState("");
const [anios,setAnios] = useState(0);

const [empleadosList,setEmpleados]= useState([]);

const add =()=>{
  Axios.post("http://localhost:3001/create",{
    nombre:nombre,
    edad:edad,
    pais:pais,
    cargo:cargo,
    anios:anios
  }).then(()=>{
    getEmpleados();
    alert("Empleado registrado");
  });
}

const getEmpleados =()=>{
  Axios.get("http://localhost:3001/empleados").then((response)=>{
    setEmpleados(response.data);
    alert("hola");
  });
}
  return (
    <div className="App">
     <div className='datos'>
      <label>Nombre: <input onChange={(event)=>{
        setNombre(event.target.value);
        }} 
        type="text"/></label>
        
      <label>Edad: <input onChange={(event)=>{
        setEdad(event.target.value);
        }}
        type="number"/></label>

      <label>Pais: <input onChange={(event)=>{
        setPais(event.target.value);
        }} 
        type="text"/></label>

      <label>Cargo: <input onChange={(event)=>{
        setCargo(event.target.value);
        }}
         type="text"/></label>

      <label>Años: <input onChange={(event)=>{
        setAnios(event.target.value);
        }} 
        type="number"/></label>

      <button onClick={add}>Registrar</button>
      </div> 
      <div className='lista'>
        <button onClick={getEmpleados}>Listar</button>
        {
          empleadosList.map((val,key)=>{
            return <div className=''> {val.nombre} </div>
          })
        }
      </div>
    </div>
  );*/
