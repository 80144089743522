import { useState } from "react";
import Axios from "axios";
import { showLoading, hideLoading } from "loading-request";
import "loading-request/dist/index.css";

function Formulario() {
  const [nombre, setNombre] = useState("");
  const [edad, setEdad] = useState(0);
  const [pais, setPais] = useState("");
  const [cargo, setCargo] = useState("");
  const [anios, setAnios] = useState(0);

  const [empleadosList, setEmpleados] = useState([]);

  const add = () => {
    Axios.post("https://nodeserver.passcargo.ec/create", {
      nombre: nombre,
      edad: edad,
      pais: pais,
      cargo: cargo,
      anios: anios,
    }).then(() => {
      getEmpleados();
      //alert("Empleado registrado");
    });
  };  
  
  const handleShowLoading = () => {
      showLoading({
        message: "Cargando...",
        spinnerColor: "#3498db",
        textLoadingColor: "#3498db",
        textLoadingSize: "30px",
      });  
      hideLoading({ timeLoading: 1500 });
  }

  const getEmpleados = () => {
    handleShowLoading();
    Axios.get("https://nodeserver.passcargo.ec/empleados").then((response) => {
      setEmpleados(response.data);      
    });
  };
  return (
    <div className="App">
      <div className="datos">
        <label>
          Nombre:{" "}
          <input
            onChange={(event) => {
              setNombre(event.target.value);
            }}
            type="text"
          />
        </label>

        <label>
          Edad:{" "}
          <input
            onChange={(event) => {
              setEdad(event.target.value);
            }}
            type="number"
          />
        </label>

        <label>
          Pais:{" "}
          <input
            onChange={(event) => {
              setPais(event.target.value);
            }}
            type="text"
          />
        </label>

        <label>
          Cargo:{" "}
          <input
            onChange={(event) => {
              setCargo(event.target.value);
            }}
            type="text"
          />
        </label>

        <label>
          Años:{" "}
          <input
            onChange={(event) => {
              setAnios(event.target.value);
            }}
            type="number"
          />
        </label>

        <button onClick={add}>Registrar</button>
      </div>
      <div className="lista">
        <button onClick={getEmpleados}>Listar</button>        
        {empleadosList.map((val, key) => {
          return <div className=""> {val.nombre} </div>;
        } ) }
      </div>
    </div>
  );
}
export default Formulario;
