import logo from "../img/header/slider-2.png";
import carruselimg1 from "../img/carrusel/aboutus.png";
import carruselimg2 from "../img/carrusel/cost.png";
import carruselimg3 from "../img/carrusel/breadcrumb-bg.png";

function Home() {
  return (
    <>
      <div className="App-home">
        <header className="App-header">
        <span class="border border-5">
          <img
            src={logo}
            style={{ width: "100%", height: "100%" }}
          ></img></span>

        </header>
        <div class="container">
          <div class="row text-center" id="loader">
            <div class="col-md-12">
              <div className="lds-ring" style={{ marginTop: 100 }}>
                <div />
                <div />
                <div />
                <div />
              </div>
              <div
                id="carouselExampleAutoplaying"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={carruselimg1} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={carruselimg2} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={carruselimg3} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <p className="loading">Cargando...</p>
            </div>
          </div>          
        </div>
        <footer class="p-5">
          <div class="row">
            <div class="col-6 col-md">
              <h5>Features</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="Login">
                    Cool stuff
                  </a>
                </li>
                <li>
                  <a class="text-muted" href="#">
                    Random feature
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <h5>Resources</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="#">
                    Resource
                  </a>
                </li>
                <li>
                  <a class="text-muted" href="#">
                    Resource name
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <h5>Resources</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="#">
                    Business
                  </a>
                </li>
                <li>
                  <a class="text-muted" href="#">
                    Education
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <h5>About</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="#">
                    Team
                  </a>
                </li>
                <li>
                  <a class="text-muted" href="./formulario">
                    Locations
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
export default Home;
