import { Navigate,Route,Routes} from "react-router-dom";
import Formulario from "../pages/Formulario";
import Home from "../pages/Home";
import Tabla from "../pages/Tabla";
import Nosotros from "../pages/Nosotros";
import Login from "../pages/Login";


function AppRouter(){
    return(
        <>
        <Routes>
            <Route path="home" element={<Home />} />
            <Route path="formulario" element={<Formulario />} />
            <Route path="tabla" element={<Tabla />} />
            <Route path="nosotros" element={<Nosotros />} />
            <Route path="login" element={<Login />} />
            

            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="*" element={<main style={{padding: "1rem"}}><p>No hay nada aqui!</p></main> } />

        </Routes>
        </>
    )
}
export default AppRouter;